/** @jsx jsx */
import React, {useState, useEffect} from "react";
import { jsx } from "theme-ui"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import Layout from "@coursemaker/gatsby-theme-coursemaker/src/components/layout";
import { logout } from "../auth/auth";
import {Link, navigate} from "gatsby";
import FastAPIClient from "../../../../auth/fast_api_auth";
import { useQueryParam, StringParam } from "use-query-params";
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

const CloseButton = ({ closeToast }) => (
    <i
        className="material-icons"
        onClick={closeToast}
    >
      continue
    </i>
);

const LoginPage = ({ pageContext }) => {
  const schoolExternalId = pageContext.school.external_id;
  let client = new FastAPIClient(schoolExternalId);
  const [error, setError] = useState(false);
  let initialEmail = "";
  let initialPassword = "";
  let initialLoading = false;
  const [authorisationCode, setAuthorisationCode] = useQueryParam("authorisation_code", StringParam);
  const [loading, setLoading] = useState(initialLoading);
  const [email, setEmail] = useState(initialEmail);
  const [password, setPassword] = useState(initialPassword);

  const validateForm = () => {
    return email.length > 0 && password.length > 0;
  }
  
  useEffect(() => {
    if (authorisationCode){
      setAuthorisationCode()
      logout()
      alert("You will now be automatically logged into your school as a test student. Click logout to preview the school in public mode.")
      setEmail("Test Student User")
      setPassword("xxxxxxx")
      setLoading(true)
      client.createTempStudentAccessToken(schoolExternalId, authorisationCode).then(() => {
          navigate('/courses')
        }).catch((err) => {
          toast.error(err);
        })
      .finally(() => {setLoading(false)});
    }
  }, [])

  const handleSubmit = (event) => {
    event.preventDefault();
    setError(false);
    if (!validateForm()){
      setError(true);
      toast.error("Please complete the login form");
      return;
    }
    setLoading(true);
    client.login(email, password)
    .then(() => {
        navigate('/courses')
      }).catch((err => {
        console.log(err)
        toast.error("Incorrect username or password");
    }))
    .finally(() => {setLoading(false)})
  }

  let schoolThemeStyle = pageContext.school?.schoolThemeStyle;
  if (!schoolThemeStyle) {
    schoolThemeStyle = {
      primaryColor: 'blue',
      secondaryColor: 'blue',
    };
  }

  return (
    <Layout pageContext={pageContext} schoolThemeStyle={schoolThemeStyle}>
      <section className="py-16 bg-indigo-100 md:py-24">
        <div className="container">
          <div className="mb-8 text-center">
            <h1>Welcome Back</h1>
            <p className="text-xl font-light text-gray-500 md:text-3xl">
              Sign in to continue
            </p>
          </div>

          <div className="mx-auto form-wrapper md:w-7/12 lg:w-4/12">
            <form className="px-8 py-10 bg-white rounded-lg shadow-lg md:px-10 md:py-12" onSubmit={handleSubmit}>
              <div className="input-fields space-y-6">
                <div className="input-field">
			            <label className="block mb-2 font-semibold text-gray-700" htmlFor="email">
				            Email
                  </label>
                  <input
                      className="w-full px-3 py-2 font-light bg-gray-100 border border-gray-300 rounded"
                      autoFocus
                      id="email"
                      type="email"
                      value={email}
                      disabled={loading}
                      placeholder="name@example.com"
                      onChange={e => setEmail(e.target.value)}
                    />
                </div>
                <div className="input-field">
                <label className="block mb-2 font-semibold text-gray-700" htmlFor="password">
				            Password
                  </label>
                  <input
                      className="w-full px-3 py-2 font-light bg-gray-100 border border-gray-300 rounded"
                      id="password"
                      type="password"
                      value={password}
                      disabled={loading}
                      onChange={e => setPassword(e.target.value)}
                    />
                </div>
                <button
                  disabled={loading}
                  type="submit"
                  className={`block w-full text-center btn btn-lg text-white bg-blue-500`}
                  sx={{
                    variant: `buttons.primary`,
                  }}>
                  {loading
                    ? <FontAwesomeIcon icon={ faSpinner } spin />
                    : "Sign In" 
                  }
                  </button>
                <div className="text-center">
                  <a href={`https://app.coursemaker.org/reset-password/${schoolExternalId}`}
                     sx={{
                       color: "primary_blue",
                     }}>
                    Forgot Password?
                  </a>
                </div>
              </div>
            </form>
            <p className="mt-5 text-center text-gray-500">
              <span>Don't have an account? </span>
              <Link
                to="/register"
                sx={{
                  color: "primary_blue",
                }}
              >
                Sign up
              </Link>
            </p>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default LoginPage;
